<template>
  <div
    class="ma-0 row-bg-color custom-container-height"
    v-if="$store.getters.getCUSTOMERCASESTableProperties"
  >
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <div class="row justify-content-between mx-2 mx-lg-0">
        <div class="col-auto col-lg-4">
          <datatable-detail
            class="col-9 col-md-9 col-sm-9"
            :table_properties="$store.getters.getCUSTOMERCASESTableProperties"
          />
        </div>
        <!-- <div class="col-auto d-flex justify-content-end mr-3 mt-5 p-0">
          <div>
            <button
              class="btn btn--export-filter"
              @click="$refs.filterSidebar.$data.filterSidebar = true"
            >
              <span class="svg-icon">
                <v-icon size="18">mdi-filter</v-icon>
              </span>
              Filter
            </button>
          </div>
        </div> -->
      </div>
      <div class="d-block d-sm-flex justify-content-between align-items-center">
        <div
          class="min-w-250px max-w-500px pl-4 mt-8 py-0 font-weight-medium w-100 text-capitalize col-sm-12 col-12 col-md-8"
        >
          <v-text-field
            v-model="case_code"
            label="Case Code"
            type="text"
            autofocus
            outlined
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            @click:append="updateTableContent"
            @keyup.enter="updateTableContent"
            :disabled="!selectedWarehouse"
          />
        </div>
      </div>

      <DataTable
        class="px-2"
        ref="datatable"
        v-if="selectedWarehouse"
        :updateTableContent="updateTableContent"
        :caseCode="this.case_code"
      />

      <!-- Filter Sidebar start -->
      <!-- <FilterSidebar
        v-if="filters.length > 0"
        ref="filterSidebar"
        :submitFilterValues="submitFilterValues"
        :resetFilterValues="resetFilterValues"
        :filters="filters"
        :setTableFiltersValues="setTableFiltersValues"
      /> -->
      <!--end::Filter Sidebar-->
    </div>
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import DataTable from "@/own/components/warehouseManagement/customerCases/DataTable.vue";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/customerCases.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";

export default {
  name: "CustomerCases",
  components: {
    DatatableDetail,
    DataTable,
    // FilterSidebar,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
    },
    store_names: {
      getTableFilters: "getCUSTOMERCASESTableFilters",
      getTableState: "getCUSTOMERCASESTableState",
      getTableData: "getCUSTOMERCASESTableData",
      getTableHeaders: "getCUSTOMERCASESTableHeaders",
      getTableProperties: "getCUSTOMERCASESTableProperties",
      getTableExportUrl: "getCUSTOMERCASESExportUrl",
      getTablePermissions: "getCUSTOMERCASESTablePermissions",
    },
    case_code: null,
  }),
  methods: {
    handleShipAction() {
      this.$refs.datatable.actionShipping();
    },
    handlePalletizeAction() {
      this.$refs.datatable.actionPalletize();
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store
          .dispatch(this.table_state.UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
            case_code: this.case_code,
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },

    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getCUSTOMERCASESTableState,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
          case_code: this.case_code,
        })
        .then(() => {})
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },

    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getCUSTOMERCASESTableState,
        warehouse_id: this.selectedWarehouse,
      };
      // console.log("state", state);
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      // this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getCUSTOMERCASESTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    setTableFiltersValues(val) {
      // console.log("setting value to filter -->", val);
      this.$store.commit(SET_TABLE_FILTER, val);
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getCUSTOMERCASESTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
  },
  beforeMount() {
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
          case_code: this.case_code,
        });
      }
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
